var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column px-6 pt-6 pb-0 background",staticStyle:{"overflowY":"auto","height":"100%","width":"100%","align-items":"center"},attrs:{"fluid":""}},[_c('v-col',{staticStyle:{"flex":"0","max-width":"800px"}},[_c('v-row',{staticClass:"mt-0 mb-6 text-h5 font-weight-bold textBlack--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.generalSettings'))+" ")])],1),_c('v-col',{staticClass:"pa-0",staticStyle:{"flex":"0","max-width":"800px"}},[_c('v-list',{staticStyle:{"width":"100%"},attrs:{"color":"background"}},[_c('v-list-item',{staticClass:"rounded px-4 py-2",staticStyle:{"width":"100%"},style:({
          border: '1px solid',
          borderColor: _vm.maxDaysToDeleteResourceIsValid
            ? _vm.$vuetify.theme.themes.light.border
            : _vm.$vuetify.theme.themes.light.warn,
          minHeight: '64px'
        })},[_c('v-list-item-title',{staticClass:"neutralPrimary--text subtitle-1",staticStyle:{"flex-wrap":"wrap","whiteSpace":"normal"}},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.maxDaysToDeleteResourceInTrash'))+" ")]),_c('v-list-item-action',[_c('input-text',{attrs:{"max-width":"80px","is-number-only":"","no-spaces":"","data-cy":"general-days-to-delete-resources","is-error":!_vm.maxDaysToDeleteResourceIsValid,"show-tooltip":!_vm.maxDaysToDeleteResourceIsValid,"tooltip-color":"warn","disabled":!_vm.$can('update', 'com.deconve.workspace.settings'),"tooltip-message":_vm.$t('deconve.setting.generalAlert.maxDays')},model:{value:(_vm.maxDaysToDeleteResourceInTrash),callback:function ($$v) {_vm.maxDaysToDeleteResourceInTrash=$$v},expression:"maxDaysToDeleteResourceInTrash"}})],1)],1),_c('v-list-item',{staticClass:"rounded px-4 py-2 mt-4",staticStyle:{"width":"100%"},style:({
          border: '1px solid',
          borderColor: _vm.$vuetify.theme.themes.light.border,
          minHeight: '64px'
        })},[_c('v-list-item-title',{staticClass:"neutralPrimary--text subtitle-1",staticStyle:{"flex-wrap":"wrap","whiteSpace":"normal"}},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.personTagMandatory'))+" ")]),_c('v-list-item-action',[_c('v-switch',{attrs:{"data-cy":"set-person-tag-required","disabled":!_vm.$can('update', 'com.deconve.workspace.settings'),"inset":""},model:{value:(_vm.isPersonTagRequired),callback:function ($$v) {_vm.isPersonTagRequired=$$v},expression:"isPersonTagRequired"}})],1)],1),_c('v-list-item',{staticClass:"rounded px-4 py-2 mt-4",staticStyle:{"width":"100%"},style:({
          border: '1px solid',
          borderColor: _vm.$vuetify.theme.themes.light.border,
          minHeight: '64px'
        })},[_c('v-list-item-title',{staticClass:"neutralPrimary--text subtitle-1",staticStyle:{"flex-wrap":"wrap","whiteSpace":"normal"}},[_vm._v(" "+_vm._s(_vm.$t('deconve.ringtone'))+" ")]),_c('v-list-item-action',[_c('v-select',{attrs:{"items":_vm.getTranslatedRingtoneOptions,"disabled":!_vm.$can('update', 'com.deconve.workspace.settings'),"item-text":"label","item-value":"value","hide-details":"","outlined":""},on:{"change":_vm.playSelectedRingtone},model:{value:(_vm.selectedRingtone),callback:function ($$v) {_vm.selectedRingtone=$$v},expression:"selectedRingtone"}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('rectangle-button',{staticClass:"mt-4 ml-auto",attrs:{"color":"primary","icon":"mdi-check","data-cy":"general-submit-button","disabled":!_vm.hasSettingsChanged || !_vm.maxDaysToDeleteResourceIsValid},on:{"clicked":_vm.handleEditSettings}},[_vm._v(" "+_vm._s(_vm.$t('deconve.submit'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container
    class="d-flex flex-column px-6 pt-6 pb-0 background"
    style="overflowY: auto; height: 100%; width: 100%; align-items: center"
    fluid
  >
    <v-col style="flex: 0; max-width: 800px">
      <v-row class="mt-0 mb-6 text-h5 font-weight-bold textBlack--text">
        {{ $t('deconve.setting.generalSettings') }}
      </v-row>
    </v-col>
    <v-col
      class="pa-0"
      style="flex: 0; max-width: 800px"
    >
      <v-list
        color="background"
        style="width: 100%"
      >
        <v-list-item
          class="rounded px-4 py-2"
          style="width: 100%"
          :style="{
            border: '1px solid',
            borderColor: maxDaysToDeleteResourceIsValid
              ? $vuetify.theme.themes.light.border
              : $vuetify.theme.themes.light.warn,
            minHeight: '64px'
          }"
        >
          <v-list-item-title
            style="flex-wrap: wrap; whiteSpace: normal"
            class="neutralPrimary--text subtitle-1"
          >
            {{ $t('deconve.setting.maxDaysToDeleteResourceInTrash') }}
          </v-list-item-title>
          <v-list-item-action>
            <input-text
              v-model="maxDaysToDeleteResourceInTrash"
              max-width="80px"
              is-number-only
              no-spaces
              data-cy="general-days-to-delete-resources"
              :is-error="!maxDaysToDeleteResourceIsValid"
              :show-tooltip="!maxDaysToDeleteResourceIsValid"
              tooltip-color="warn"
              :disabled="!$can('update', 'com.deconve.workspace.settings')"
              :tooltip-message="$t('deconve.setting.generalAlert.maxDays')"
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          class="rounded px-4 py-2 mt-4"
          style="width: 100%"
          :style="{
            border: '1px solid',
            borderColor: $vuetify.theme.themes.light.border,
            minHeight: '64px'
          }"
        >
          <v-list-item-title
            style="flex-wrap: wrap; whiteSpace: normal"
            class="neutralPrimary--text subtitle-1"
          >
            {{ $t('deconve.setting.personTagMandatory') }}
          </v-list-item-title>
          <v-list-item-action>
            <v-switch
              v-model="isPersonTagRequired"
              data-cy="set-person-tag-required"
              :disabled="!$can('update', 'com.deconve.workspace.settings')"
              inset
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          class="rounded px-4 py-2 mt-4"
          style="width: 100%"
          :style="{
            border: '1px solid',
            borderColor: $vuetify.theme.themes.light.border,
            minHeight: '64px'
          }"
        >
          <v-list-item-title
            style="flex-wrap: wrap; whiteSpace: normal"
            class="neutralPrimary--text subtitle-1"
          >
            {{ $t('deconve.ringtone') }}
          </v-list-item-title>
          <v-list-item-action>
            <v-select
              v-model="selectedRingtone"
              :items="getTranslatedRingtoneOptions"
              :disabled="!$can('update', 'com.deconve.workspace.settings')"
              item-text="label"
              item-value="value"
              hide-details
              outlined
              @change="playSelectedRingtone"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div
        class="d-flex justify-end"
        style="width: 100%"
      >
        <rectangle-button
          class="mt-4 ml-auto"
          color="primary"
          icon="mdi-check"
          data-cy="general-submit-button"
          :disabled="!hasSettingsChanged || !maxDaysToDeleteResourceIsValid"
          @clicked="handleEditSettings"
        >
          {{ $t('deconve.submit') }}
        </rectangle-button>
      </div>
    </v-col>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { DEFAULT_RINGTONE, getRingtoneFileName } from '@/utils/ringtone';

import InputText from '../../components/InputText.vue';
import RectangleButton from '../../components/RectangleButton.vue';

export default {
  name: 'GeneralSettings',
  components: {
    InputText,
    RectangleButton,
  },
  data() {
    return (
      {
        isPersonTagRequired: false,
        maxDaysToDeleteResourceInTrash: '0',
        selectedRingtone: DEFAULT_RINGTONE,
      }
    );
  },
  computed: {
    ...mapGetters({
      myWorkspace: 'workspace/myWorkspace',
    }),
    maxDaysToDeleteResourceIsValid() {
      if (this.maxDaysToDeleteResourceInTrash <= 30) {
        return true;
      }

      return false;
    },
    getTranslatedRingtoneOptions() {
      return [
        {
          label: this.$t('deconve.ringtoneChord'),
          value: 'chord-alert-ringtone.mp3',
        },
        {
          label: this.$t('deconve.ringtoneJiggle'),
          value: 'jiggle-alert-ringtone.mp3',
        },
        {
          label: this.$t('deconve.ringtoneNotification'),
          value: 'notification-alert-ringtone.mp3',
        },
      ];
    },
    hasSettingsChanged() {
      if (this.myWorkspace) {
        const {
          is_person_tag_required: isPersonTagRequired,
          number_of_days_to_delete_item_permanently: numberOfDaysToDeleteItem,
          ringtone,
        } = this.myWorkspace;

        if (this.isPersonTagRequired !== isPersonTagRequired) return true;

        if (Number(this.maxDaysToDeleteResourceInTrash) !== numberOfDaysToDeleteItem) return true;

        if (this.selectedRingtone !== getRingtoneFileName(ringtone)) return true;
      }

      return false;
    },
  },
  watch: {
    myWorkspace() {
      this.initSettings(this.myWorkspace);
    },
  },
  created() {
    this.initSettings(this.myWorkspace);
  },
  methods: {
    ...mapActions({
      editWorkspace: 'workspace/editWorkspace',
    }),
    handleEditSettings() {
      const data = {
        ringtone: this.selectedRingtone,
        // eslint-disable-next-line @typescript-eslint/camelcase
        number_of_days_to_delete_item_permanently: Number(this.maxDaysToDeleteResourceInTrash),
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_person_tag_required: this.isPersonTagRequired,
      };

      this.editWorkspace({ workspaceId: this.myWorkspace.id, data });
    },
    initSettings(workspace) {
      const {
        is_person_tag_required: isPersonTagRequired,
        number_of_days_to_delete_item_permanently: numberOfDaysToDeleteItem,
        ringtone,
      } = workspace;

      this.maxDaysToDeleteResourceInTrash = String(numberOfDaysToDeleteItem);
      this.isPersonTagRequired = isPersonTagRequired;
      this.selectedRingtone = getRingtoneFileName(ringtone);
    },

    playSelectedRingtone(ringtone) {
      const audio = new Audio(`/sounds/${ringtone}`);

      audio.play();
    },
  },
};
</script>

<style>
.v-text-field fieldset {
  border-color: rgb(223, 227, 232) !important;
  background-color: rgb(250, 250, 250) !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid currentColor !important;
}
</style>
